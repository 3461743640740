import { Link } from '../link/Link';
import './TitleBar.css';;
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { clearChat } from '../chat/chatSlice';
import { Button } from '@mui/material';
import { useClientOnly, ClientOnly } from 'react-client-only';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import { loadState } from '../../app/browser-storage/browser-storage';
import fileDownload from 'js-file-download';



export const TitleBar = () => {
  const dispatch = useAppDispatch()
  const isOnClient = useClientOnly();
  const resetInProgress = useAppSelector((state: RootState) => state.chat.resetInProgress);

  const handleExport = () => {
    const loadedState = loadState();
    fileDownload(JSON.stringify(loadedState, null, 2), 'export_' + (new Date()).toISOString() + '.json');

  };

  const handleReset = () => {
    dispatch(clearChat());
  };

  const exportLabel = isOnClient ? <><FontAwesomeIcon icon={faDownload} />&nbsp;&nbsp;Export</> : 'Export';
  const resetLabel = isOnClient ? <><FontAwesomeIcon icon={faTrash} />&nbsp;&nbsp;Reset</> : 'Reset';
  const resetDisabled = resetInProgress ? true : false;

  return (
    <div className="titleBar">
      <div className="titleContainer">
        <h1>UI Pilot</h1>
        <Link className="navitem" href="/">
          Home
        </Link>
        <Link className="navitem" href="/about">
          About
        </Link>
      </div>

      <div className="buttonsContainer" style={{height: '100%' }}>
        <div>
          <Button className="exportButton" sx={{marginTop: '2px !important', marginRight: '4px !important'}} variant="outlined" onClick={handleExport}>
            { exportLabel }
          </Button>
          <Button className="resetButton" sx={{marginTop: '2px !important', marginRight: '4px !important'}} variant="outlined" onClick={handleReset} disabled={resetDisabled}>
            { resetLabel }
          </Button>
        </div>
      </div>
    </div>
  );
};
