import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import counterReducer from "../features/counter/counterSlice"
import chatReducer from "../features/chat/chatSlice"

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    chat: chatReducer
  },
  middleware: []
})

export const getStore = (PRELOADED_STATE) => {
  return configureStore({
    reducer: {
      counter: counterReducer,
      chat: chatReducer
    },
    preloadedState: PRELOADED_STATE
  })
};

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
